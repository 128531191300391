<template>
  <div class="prize-page">
    <div class="hasNoPrize" v-if="!addressList.length">
      <div class="noPrizeImg">
        <img src="./img/noPrizeImg.png">
      </div>
      <div class="noPrizeText">
        暂无奖品
      </div>
    </div>
    <div class="myPrizeContact noPrizeFooter" style="display: block;">
      <div class="tShirt" style="display: none;">
        <!-- <span id="openTshirt">T恤尺寸表</span> -->
        <span id="openMyAddress">我的收货地址</span>
      </div>
      <div class="contact">
        平台客服微信：youyujz01 （工作日：9:00-18:00）
      </div>
      </div>
  </div>
</template>
<script>
import { getAppStatus, callAppHandler } from '@/utils/app';
import { getQueryWinnerAddress } from '@/api/base';

export default {
  name: 'GoldPrize',
  data() {
    return {
      addressList: [],
      cuserId: getAppStatus().cuserId,
    };
  },
  computed: {
    cuserIds() {
      // return '000dfea3-8605-4c06-ac67-bfbfad76ad62';
      return getAppStatus().cuserId;
    },
  },
  mounted() {
    document.title = '我的奖品';
    this.cuserId = this.cuserId || sessionStorage.getItem('userId');
    this.cuserId && this.initData();
    if (!this.cuserId) {
      callAppHandler('jzUserId', null, (res) => {
        sessionStorage.setItem('userId', res);
        this.cuserId = res;
        this.initData();
      });
    }
  },
  methods: {
    async initData() {
      let res;
      try {
        res = await getQueryWinnerAddress({ cuserid: this.cuserId });
      } catch (e) {
        console.log(e);
      }
      if (res?.code === 1) {
        this.winnerAddressList = res.results.winnerAddressList;
      }
    },
    initPrizeList() {},
  },
};
</script>
<style scoped lang="scss">
.prize-page {
  width: 100%;
  height: 100%;
  position: relative;
}
.hasNoPrize {
  padding: 44.48% 0 0 0;
  margin: 0 auto;
}
.noPrizeImg, .noRecordImg {
  width: 21.33%;
  margin: 0 auto 0.682666rem auto;
  img {
    width: 100%;
  }
}
.noPrizeText, .noRecordText {
  text-align: center;
  font-size: 0.426666rem;
  letter-spacing: -0.4px;
  color: #333333;
}
.noPrizeFooter {
  position: absolute;
  bottom: 0.266666rem;
  width: 100%;
}
.myPrizeContact {
  margin-top: 8.88%;
}
.contact {
  text-align: center;
  font-size: 0.346666rem;
  letter-spacing: -0.3px;
  color: #999999;
  margin-bottom: 0.266666rem;
}
</style>
